import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

const Landing = (props) => (
  <Layout>
    <Helmet>
      <title>Acuarios Irrigation Privacy Policy</title>
      <meta
        name="description"
        content="Acuarios Irrigation Privacy Policy Page"
      />
    </Helmet>
    <div id="main">
      <section id="two" className="spotlights">
        <section>
          <div className="content" style={{ margin: '0 auto', w: '60%' }}>
            <header className="major">
              <h1>Privacy Policy</h1>
            </header>
            <p>
              <h4>Acuarios Irrigation Privacy Policy</h4>
              <p>
                This Privacy Policy describes how your personal information is
                collected, used, and shared when you visit or make a purchase
                from aquariosirrigation.com (the &ldquo;Site&rdquo;).
              </p>
              <h4>PERSONAL INFORMATION WE COLLECT</h4>
              <p>
                When you visit the Site, we automatically collect certain
                information about your device, including information about your
                web browser, IP address, time zone, and some of the cookies that
                are installed on your device. Additionally, as you browse the
                Site, we collect information about the individual web pages or
                products that you view, what websites or search terms referred
                you to the Site, and information about how you interact with the
                Site. We refer to this automatically-collected information as
                &ldquo;Device Information.&rdquo;
              </p>
              <h4>
                We collect Device Information using the following technologies:
              </h4>
              <p>
                &nbsp; &nbsp; - &ldquo;Cookies&rdquo; are data files that are
                placed on your device or computer and often include an anonymous
                unique identifier. For more information about cookies, and how
                to disable cookies, visit http://www.allaboutcookies.org.
              </p>
              <p>
                &nbsp; &nbsp; - &ldquo;Log files&rdquo; track actions occurring
                on the Site, and collect data including your IP address, browser
                type, Internet service provider, referring/exit pages, and
                date/time stamps.
              </p>
              <p>
                &nbsp; &nbsp; - &ldquo;Web beacons,&rdquo; &ldquo;tags,&rdquo;
                and &ldquo;pixels&rdquo; are electronic files used to record
                information about how you browse the Site.
              </p>
              <p>
                When we talk about &ldquo;Personal Information&rdquo; in this
                Privacy Policy, we are talking both about Device Information and
                Order Information.
              </p>
              <h4>HOW DO WE USE YOUR PERSONAL INFORMATION?</h4>
              <p>
                We share your Personal Information with third parties to help us
                use your Personal Information, as described above. &nbsp;For
                example, we use Google Analytics to help us understand how our
                customers use the Site--you can read more about how Google uses
                your Personal Information here:
                &nbsp;https://www.google.com/intl/en/policies/privacy/.
                &nbsp;You can also opt-out of Google Analytics here:
                &nbsp;https://tools.google.com/dlpage/gaoptout.
              </p>
              <p>
                Finally, we may also share your Personal Information to comply
                with applicable laws and regulations, to respond to a subpoena,
                search warrant or other lawful request for information we
                receive, or to otherwise protect our rights.
              </p>
              <h4>BEHAVIOURAL ADVERTISING</h4>
              <p>
                As described above, we use your Personal Information to provide
                you with targeted advertisements or marketing communications we
                believe may be of interest to you. &nbsp;For more information
                about how targeted advertising works, you can visit the Network
                Advertising Initiative&rsquo;s (&ldquo;NAI&rdquo;) educational
                page at
                http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
              </p>
              <p>You can opt out of targeted advertising by:</p>
              <p>&nbsp; COMMON LINKS INCLUDE:</p>
              <p>
                &nbsp; &nbsp; FACEBOOK -
                https://www.facebook.com/settings/?tab=ads
              </p>
              <p>
                &nbsp; &nbsp; GOOGLE -
                https://www.google.com/settings/ads/anonymous
              </p>
              <p>
                &nbsp; &nbsp; BING -
                https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
              </p>
              <p>
                Additionally, you can opt out of some of these services by
                visiting the Digital Advertising Alliance&rsquo;s opt-out portal
                at: &nbsp;http://optout.aboutads.info/.
              </p>

              <h4>DO NOT TRACK</h4>
              <p>
                Please note that we do not alter our Site&rsquo;s data
                collection and use practices when we see a Do Not Track signal
                from your browser.
              </p>

              <h4>YOUR RIGHTS</h4>
              <p>
                If you are a European resident, you have the right to access
                personal information we hold about you and to ask that your
                personal information be corrected, updated, or deleted. If you
                would like to exercise this right, please contact us through the
                contact information below.Additionally, if you are a European
                resident we note that we are processing your information in
                order to fulfill contracts we might have with you (for example
                if you make an order through the Site), or otherwise to pursue
                our legitimate business interests listed above.
                &nbsp;Additionally, please note that your information will be
                transferred outside of Europe, including to Canada and the
                United States.
              </p>
              <h4>DATA RETENTION</h4>
              <p>
                When you place an order through the Site, we will maintain your
                Order Information for our records unless and until you ask us to
                delete this information.
              </p>
              <h4>CHANGES</h4>
              <p>
                We may update this privacy policy from time to time in order to
                reflect, for example, changes to our practices or for other
                operational, legal or regulatory reasons.CONTACT US
              </p>
              <p>
                For more information about our privacy practices, if you have
                questions, or if you would like to make a complaint, please
                contact us by e-mail at contacto@aquariosirrigation.com or by
                mail using the details provided below:
              </p>
              <p>&nbsp; 5720 84st , Flushing, NY, 11373, United States</p>
            </p>
          </div>
        </section>
      </section>
    </div>
  </Layout>
)

export default Landing
